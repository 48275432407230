@import "./element/scrollbar.scss";

body{
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body, #root, .App{
    overflow: auto;
    height: 100%;
}

.App{
    @include scroll-bar();
}

a{
    color: #333;
    text-decoration: none;
}

p{
    margin: 20px 0;
}

.hidden{
    display: none !important;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}
caption,
th,
td{
    //	/* float: none 阻止 span-想类破坏表格单元的显示 */
    float: none !important;
    font-weight: normal;
    text-align: left;
}
th,
td{
    height: auto;
    min-width: 100px;
    border: 1px solid #c0c0c0;
    padding: 10px 5px;
    color: #000;
    line-height: 2;
    text-indent: 0;
}

.cm-editor{
    font-size: 16px;
}

@import "./animate/index.scss";

@import "./element/index.scss";

@import "./layout/index.scss";

@import "./svg/index.scss";

@import "./component/index.scss";

@import "./view/index.scss";

.Test .icon{
    display: inline-block;
    border: 5px solid #000000;
    margin: 10px;
    padding: 10px;
    background: #fff;
    color: #000;
    font-size: 40px;
}