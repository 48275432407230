@import "../layout/flex-container.scss";

.header{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
    z-index: 100;
    background: #000;
    font-size: 16px;
    line-height: 40px;

    &_content,
    &_searchBar{
        margin: auto;

        @include flex-container();
    }

    &_searchBar{
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        width: auto;
        //height: 50px;
        border: 1px solid #000000;
        border-top: 0;
        padding: 5px;
        background: #fff;

        form{
            display: block;
            width: 100%;

            .keywordBar{
                overflow: hidden;
                border: 1px solid #c0c0c0;
                border-radius: 5px;
                box-sizing: border-box;

                @include flex-container();

                .input{
                    outline: none;
                }
            }
            .input{
                width: 100%;
                border: 0;
            }
            .btn{
                border: 0;
                border-left: 1px solid #c0c0c0;
                border-radius: 0;
            }
        }
        .filterList{
            @include flex-container();
            @include flex-justify( left );
            @include flex-wrap();

            .icon-checkbox{
                margin: 5px 5px 0 0;

                //.icon{
                //    font-size: 16px;
                //    line-height: 40px;
                //    margin-left: 5px;
                //    height: 40px;
                //    display: inline-block;
                //    vertical-align: bottom;
                //}
            }
        }
    }

    .logo{
        display: block;
        width: 30px;
        height: 30px;
        margin: 5px;
    }
    .avatar{
        width: 30px;
        height: 30px;
        margin: 5px;
        border-radius: 15px;
    }

    &_content{
        .icon{
            width: 30px;
            height: 30px;
            margin: 5px;
            color: #fff;
            font-size: 28px;
            line-height: 30px;
            text-align: center;
            border-radius: 5px;

            &.active{
                outline: 0;
                background: #fff;
                color: #000;
            }
            &.current{
                outline: 1px dashed #c0c0c0;
            }
        }
        .icon-image-resize{
            display: none;
        }
        .icon-left{
            //width: 15px;
        }
    }
}

@media screen and (min-width: 1000px){
    .header_content,
    .header_searchBar{
        width: 1000px;

        .icon-image-resize{
            display: block;
        }
    }
}