@keyframes bounce{
    0%, 100%{
        transform: scale(0.0);
    }
    50%{
        transform: scale(1.0);
    }
}

.animate-bounce{
    animation-name: bounce;
}