@import "../element/icon.scss";
@import "../layout/grid-container.scss";

.editor{
    &::before{
        @extend %icon-code;
    }

    .container.grid{
        overflow: hidden;
        margin: -5px -5px -5px;
    }

    &_part{
        overflow: hidden;
        position: relative;
        border-radius: 5px;
        margin: 5px;
        background: #fff;

        h3{
            height: 30px;
            border-bottom: 1px solid #c0c0c0;
            margin: 10px;
            padding-left: 5px;
            line-height: 30px;
            box-sizing: border-box;
            background: #fff;
        }

        & > div{
            position: absolute;
            top: 50px;
            bottom: 0;
            left: 0;
            right: 0;
            background: #fff;
        }
    }
    .cm-editor{
        overflow: hidden;
        height: 100%;
    }

    &_result{
        @include grid(5, 18);
    }

    iframe{
        width: 100%;
        height: 100%;
        border: 0;
        margin: 0;
    }

    &_html,
    &_css,
    &_js{
        @include grid(3, 6);
    }
}

@media screen and (max-width: 450px){
    .editor{
        &_result,
        &_html,
        &_css,
        &_js{
            @include grid-column( 8 );
        }
    }
}