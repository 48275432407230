@mixin text-limit($line){
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: $line;
}

@mixin text-fixed($line, $lineHeight){
    overflow: hidden;
    display: -webkit-box;
    height: $lineHeight * $line;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: $line;
}

@mixin text-align($align: left){
    text-align: $align;
}

@mixin font-weight($weight: bold){
    font-weight: $weight;
}

.text{
    &-center,
    &.center{
        @include text-align( center );
    }
    &-right,
    &.right{
        @include text-align( right );
    }
}

.bold{
    @include font-weight();
}
.bolder{
    @include font-weight( bolder );
}
.light{
    @include font-weight( lighter );
}