//---------- radar 雷达图 ----------
/*svg*/.radar{
    &_hexagon{
        stroke: #c0c0c0;
        stroke-width: 1px;
        fill: transparent;
    }

    &_axis{
        font-size: 12px;
    }

    &_line{
        stroke: #c0c0c0;
        stroke-dasharray: 5 5;
    }

    &_path{
        fill: #ff6a07;
        stroke: #d35400;
        stroke-width: 1px;
        opacity: .5;
    }
    &_point{
        fill: #fff;
        stroke: #d35400;
        stroke-width: 1px;

        //&:hover{
        //	stroke: #d35400;
        //}
    }
}