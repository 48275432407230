//---------- 库文件-颜色库 ----------

/**
 * 各大网站主颜色
 */
$alibaba:	#f90;
$taobao:    #ff4400;
$tmall:     #b10000;
$baidu:     #0000cc;
$huawei:    #e30a12;
$htc:       #69b40f;
$jd:        #c91623;
$renren:    #105ba3;
$sina:      #e4351e;
$sohu:      #fdd000;
$tencent:   #0397de;
$wechat:    #5ba607;
$xiami:     #f39200;
$zhihu:     #0078d8;
$net163:    #333;
$kr36:      #146fb9;
$net360:    #48b701;

//---------- 全局颜色 ----------
// 颜色变量以 c 为前缀
//----- 颜色库 -----
$c0:    #e1e1e1;
//----- 中国风（一） -----
$c42:   #ffb3a7;// 粉红
$c43:   #ef7a82;// 嫣红
$c44:   #db5a6b;// 海棠红
$c45:   #cb3a56;// 茜色
$c46:   #c32136;// 棗红
$c47:   #be002f;// 殷红
$c9:    #9d2933;// 胭脂红
$c12:   #c3272b;// 赤
$c48:   #c83c23;// 绯红
$c16:   #ed5736;// 妃色
$c49:   #f9906f;// 酡颜
$c50:   #f05654;// 银红
$c52:   #f00056;// 品红
$c53:   #ff0097;// 洋红
$c54:   #8c4356;// 绛紫
$c55:   #4c221b;// 紫檀
$c56:   #60281e;// 栗色
$c11:   #b36d61;// 檀
$c13:   #a98175;// 绾
$c57:   #955539;// 赭
$c58:   #b35c44;// 茶色
$c59:   #ffa400;// 橘黄
$c60:   #ff8c31;// 杏红
$c61:   #ffa631;// 杏黄
$c62:   #ffc773;// 薑黄
$c63:   #f0c239;// 缃色
$c64:   #ffa400;// 橙黄
$c65:   #ff7500;// 橘红
$c66:   #ff4c00;// 朱红
$c15:   #f30;// 炎
$c67:   #f20c00;// 石榴红
$c68:   #ff2121;// 大红
$c69:   #dc3023;// 酡红
$c70:   #ff4e20;// 丹
$c71:   #f35336;// 彤
$c72:   #fa8c35;// 橙色
$c73:   #e29c45;// 黄櫨
$c74:   #f2b345;// 赤金
$c75:   #faff72;// 鸭黄
$c76:   #fff143;// 鹅黄
$c6:    #d9b611;// 秋香色
$c77:   #ca6924;// 琥珀
$c78:	#b25d25;// 棕色
$c79:	#9b4400;// 棕红
$c80:	#ae7000;// 棕黄
$c81:	#7c4b00;// 棕黑
$c82:	#6e511e;// 褐色
$c83:	#896c39;// 秋色
$c3:	#789262;// 竹青
$c84:	#827100;// 棕绿
$c85:	#c89b40;// 昏黄
$c86:	#d3b17d;// 枯黄
$c87:	#eaff56;// 樱草色
$c88:	#c9dd22;// 柳黄
$c89:	#bddd22;// 嫩绿
$c90:	#afdd22;// 柳绿
$c91:	#93d900;// 葱绿
$c93:	#00bc12;// 油绿
$c94:	#0c8918;// 绿沈
$c95:	#0aa344;// 青葱
$c96:	#0eb83a;// 葱青
$c97:	#00e079;// 青翠
$c98:	#40de5a;// 草绿
$c99:	#94d048;// 豆绿
$c100:	#bce672;// 松花色
$c101:	#e0eee8;// 鸭卵青
$c102:	#7fecad;// 缥
$c103:	#3de1ad;// 翡翠色
$c104:	#00e09e;// 青色
$c105:	#21a675;// 松柏绿
$c106:	#057748;// 松花绿
$c107:	#48c0a3;// 青碧
$c108:	#1bd1a5;// 碧色
$c109:	#7bcfa6;// 石青
$c18:	#a4e2c6;// 艾绿
$c110:	#c0ebd7;// 青白
$c14:	#d4f2e7;// 水绿
$c111:	#25f8cb;// 湖绿
$c112:	#70f3ff;// 蔚蓝
$c113:	#3eede7;// 碧蓝
$c114:	#30dff3;// 湖蓝
$c116:	#4c8dae;// 群青
$c7:	#177cb0;// 靛青
$c117:	#065279;// 靛蓝
$c118:	#003371;// 绀青
$c119:	#3b2e7e;// 藏蓝
$c120:	#2e4e7e;// 藏青
$c121:	#4b5cc4;// 宝蓝
$c122:	#758a99;// 墨灰
$c123:	#a1afc9;// 蓝灰色
$c124:	#e0f0e9;// 素
$c125:	#fcefe8;// 鱼肚白
$c126:	#f3d3e7;// 水红
$c127:	#edd1d8;// 藕色
$c128:	#815463;// 紫酱
$c129:	#815476;// 酱紫
$c130:	#cca4e3;// 丁香色
$c131:	#8d4bbb;// 紫色
$c132:	#801dae;// 青莲
$c133:	#56004f;// 紫棠
$c134:	#574266;// 黛紫
$c135:	#392f41;// 乌黑
$c4:	#4a4266;// 黛
$c136:	#6b6882;// 黝
$c137:	#725e82;// 乌色
$c138:	#b0a4e3;// 雪青
$c139:	#f2ecde;// 缟
$c140:	#fffbf0;// 象牙白
$c142:	#f0fcff;// 雪白
$c143:	#e9f1f6;// 霜色
$c20:	#d6ecf0;// 月白
$c144:	#88ada6;// 水色
$c145:	#549688;// 铜绿
$c146:	#426666;// 黛绿
$c147:	#41555d;// 黯
$c148:	#50616d;// 墨色
$c19:	#425066;// 黛蓝
$c10:	#424c50;// 鸦青
$c149:	#75878a;// 苍色
$c150:	#bbcdc5;// 蟹壳青
$c151:	#f0f0f4;// 铅白
$c8:	#f3f9f1;// 茶白
$c152:	#eedeb0;// 牙色
$c5:	#a88462;// 驼色
$c17:	#75664d;// 黎
$c153:	#5d513c;// 黧
$c154:	#493131;// 缁色
$c155:	#808080;// 灰色
$c156:	#3d3b4f;// 玄青
$c157:	#161823;// 漆黑
$c21:	#efdeb0;// 牙白
//----- 小清新 -----
$c22:	#1abc9c;// Turquoise
$c24:	#3498db;// Peter river
$c25:	#9b59b6;// Amethyst
$c26:	#34495e;// Wet asphalt
$c27:	#16a085;// Green sea
$c28:	#27ae60;// Nephrite
$c30:	#8e44ad;// Wisteria
$c31:	#2c3e50;// Midnight blue
$c33:	#f39c12;// Orange
$c34:	#e67e22;// Carrot
$c36:	#e74c3c;// Alizarin
$c37:	#c0392b;// Pomegranate
$c38:	#ecf0f1;// Clouds
$c39:	#bdc3c7;// Silver
$c40:	#95a5a6;// Concrete
$c41:	#7f8c8d;// Asbestos
//----- 亮色 -----
$c158:	#ffe800;//
$c159:	#14a2d4;
$c160:	#af5e9c;//rgb(175, 94, 156);
$c161:	#00b16a;//rgb(0, 177, 106);
$c162:	#f7941e;//rgb(247, 148, 30);
$c163:	#00529c;//rgb(0, 82, 156);
//----- 复古色 -----
$c166:	#f37021;//rgb(243, 112, 33);
$c167:	#aa4630;//rgb(170, 70, 48);
$c168:	#e98d70;//rgb(233, 141, 112);
$c169:	#553679;//rgb(85, 54, 121);
$c170:	#004983;//rgb(0, 73, 131);
$c171:	#235800;//rgb(35, 88, 0);
//----- 单一色 -----
$c164:	#00529c;//rgb(0, 82, 156);
//$c165:	rgba(0, 82, 156, 0.5); 0.2
$c165:	#00b16a;//rgb(0, 177, 106);

$c172:	#7fffaa;// 碧绿色
$c173:	#ffffe0;// lightyellow

//----- Metro Color -----
$c174:	#252525;
$c175:	#006ac1;
$c176:	#691b88;
$c177:	#f48300;
$c178:	#001e4e;
$c179:	#1faeff;
$c180:	#78ba00;
$c181:	#008287;
$c182:	#1b58b8;
$c183:	#2673ec;
$c184:	#004d60;
$c185:	#56c5ff;
$c186:	#ae113d;
$c187:	#199900;
$c188:	#569ce3;
$c189:	#632f00;
$c190:	#004a00;
$c191:	#00d8cc;
$c192:	#2e1700;
$c193:	#00c13f;
$c194:	#00aaaa;
$c195:	#b01e00;
$c196:	#15992a;
$c197:	#91d100;
$c198:	#4e0000;
$c199:	#ff9810;
$c200:	#b81b6c;
$c201:	#4e0038;
$c202:	#e56c19;
$c203:	#e1b700;
$c204:	#c1004f;
$c205:	#ff2e12;
$c206:	#d39d09;
$c207:	#7200ac;
$c208:	#b81b1b;
$c209:	#ff76bc;
$c210:	#2d004e;
$c211:	#ff1d77;
$c212:	#e064b7;
$c213:	#4617b4;
$c214:	#83ba1f;
$c215:	#00a4a4;
$c216:	#1f0068;
$c217:	#aa40ff;
$c218:	#ff7d23;

$c219:	#76d17f;//rgb(118, 209, 127);

$c220:	#00bfff;
$c221:	#00ffff;
$c222:	#f9f2f4;
$c223:	#c7254e;
$c224:	#999;
$c225:	#eee;
$c226:	#ccc;
$c227:	#f88;
$c228:	#555;
$c229:	#d35400;
$c230:  #fafafa;
$c231:  #868686;
$c232:  #949494;
$c233:  #333;

$c234:  rgba(0, 0, 0, .5);

$c235:  #f1f1f1;

$c236:  #f7f7f7;

//---------- 网站色调 ----------
// 动态磁砖背景颜色
$c1:    #efefef;

// 边框颜色
$c2:	#c0c0c0;

// 文字颜色（背景非白色），或 详细内容背景颜色
$c141:	#fff;// 精白
// 文字颜色（背景为白色），或 标签 背景颜色
$c23:	#2ecc71;// Emeralnd

// 当前操作项 背景颜色，或子列表 背景颜色，或 鼠标悬浮 文字颜色
$c35:	#d35400;// Pumpkin

// 主模块（main）背景颜色，或 动态磁砖 outline 颜色或 普通信息提示 背景颜色
$c115:  #44cef6;// 蓝

// 子模块 背景颜色 边框颜色
$c29:	#2980b9;// Belize hole

// 成功提示颜色
$c92:	#00e500;// 绿色

// 错误提示颜色
$c51:	#ff2d51;// 火红

// 表单项获得焦点时提示颜色
$c32:	#f1c40f;// Sun flower