@import "../element/icon.scss";
@import "../element/tag.scss";
@import "../element/text.scss";
@import "../element/scrollbar.scss";
@import "../layout/flex-container.scss";
@import "../layout/grid-container.scss";
@import "../layout/img-container.scss";

$descLineHeight: 1.5em;

$moduleMargin: 5px;
$moduleBorder: 5px;
$modulePadding: 5px;

@function grid-content-width($col){
    @return $col * $gridColWidth - ($moduleBorder * 2 + $moduleMargin * 2 + $modulePadding * 2);
}
@function grid-content-height($row){
    @return $row * $gridColWidth - ($moduleBorder * 2 + $moduleMargin * 2 + $modulePadding * 2);
}

@mixin album-preview-calc($col, $row, $rowNum, $colNum: 1){
    width: calc( grid-content-width($col) / $rowNum );
    height: calc( ($row * $gridColWidth) / $colNum );
}
@mixin album-preview($col, $row, $rowNum, $colNum: 1){
    .news_preview{
        width: calc( grid-content-width($col) / $rowNum );
        height: calc( grid-content-height($row) / $colNum );
    }
}
@mixin news-left($start: 1, $end: 17){
    @include grid-offset($start, $end);
}
@mixin news-right($start: 17, $end: 25){
    @include grid-offset($start, $end);
}

/**
 * todo 重新设计网格系统
 **/

.index{
    margin: -5px 0;

    .loading{
        @include grid(8, 1);
    }
}

.module{
    position: relative;
    border: $moduleBorder solid #c0c0c0;
    border-radius: 10px;
    margin: $moduleMargin;
    padding: $modulePadding;
    color: #333;

    &::before{
        position: absolute;
        top: -($moduleBorder + $modulePadding);
        left: -($moduleBorder + $modulePadding);
        z-index: 1;
        width: $gridColWidth - $moduleBorder * 2;
        height: $gridRowHeight - $moduleBorder * 2;
        border: $moduleBorder solid #c0c0c0;
        border-radius: 10px 25px 25px;
        background: #fff;
        color: #333;
        font-family: 'font', serif;
        font-size: 24px;
        text-align: center;
        line-height: $gridRowHeight - $moduleBorder * 2;
        font-weight: bolder;
        content: '';
    }

    &_title{
        position: relative;
        margin: -$modulePadding 0 (-$modulePadding) (grid-content-height( 1 ) + 10px);
        padding-left: 10px;
        line-height: 40px;
        text-align: left;

        &::after{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: #c0c0c0;
            content: '';
        }
    }
    &_content{
        border-radius: 5px;
        margin: 10px 0 0;
        padding: 5px;
        background: #f1f1f1;
    }
    &_info{
        @include flex-container();

        position: relative;
        margin-top: 10px;
        color: #999;
        font-size: 14px;

        &:before{
            position: absolute;
            top: -5px;
            left: 0;
            right: 0;
            height: 1px;
            background: #c0c0c0;
            content: "";
        }
    }
    &_tags{
        @extend .tagList;
    }
    &_datetime{
        flex-shrink: 0;
        height: grid-content-height( 1 ) + 10px;
        line-height: grid-content-height( 1 ) + 10px;
    }
}

.modal{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: rgba(0, 0, 0, .5);
}

.alert{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 400px;
    height: 200px;
    margin: auto;
    background: #fff;

    &::before{
        @extend %icon-warming;
    }

    &_content{
        height: 65px;
        border-radius: 5px;
        padding: 10px;
        background: #fff;

        @include scroll-bar();
    }
    &_op{
        margin-top: 10px;
        text-align: center;
    }
}

.news{
    @include grid(8, 4);

    .module_title{
        height: grid-content-height( 1 ) + 20px;

        @include text-limit( 1 );
    }

    &_pin{
        position: absolute;
        top: 6px;
        right: 11px;
        font-size: grid-content-height( 1 ) + 10px;
        transform: rotate( 45deg );
    }
    &_new,
    &_update{
        position: absolute;
        top: $modulePadding;
        right: $modulePadding;
        font-size: grid-content-height( 1 ) + 10px;
    }

    &_content{
        position: relative;
        margin-top: 10px;

        @include flex-container();
        @include flex-justify( left );
    }

    &_preview{
        border-radius: 5px;

        @include flex-container();
        @include flex-justify( center );
        @include flex-align( center )
    }

    &_detail{
        width: 100%;
        color: #666;
        font-size: 14px;
    }
    &_desc{
        line-height: $descLineHeight;
    }
    &_info{
        @extend .module_info;

        width: 100%;
    }
    &_tags{
        @extend .module_tags;

        @include text-fixed(1, grid-content-height( 1 ) + 20px);
    }
    &_datetime{
        @extend .module_datetime;
        
        margin-left: 10px;
    }

    &.blog{
        @include grid-row( 5 );

        .news_content{
            @include flex-wrap();
        }

        .news_preview{
            height: grid-content-height( 4 ) - 30px;
        }
        .news_detail{
            height: grid-content-height( 4 ) - 30px;

            @include flex-container();
            @include flex-container-v();
            @include flex-align( stretch );
        }
        .news_preview + .news_detail{
            position: absolute;
            width: 100%;
            border-radius: 5px;
            background: rgba(0, 0, 0, .5);
            color: #fff;

            .news_desc{
                padding-top: 5px;
                padding-left: 10px;
                text-indent: 0;
            }
        }
        .news_desc{
            flex: 1;
            text-indent: 2em;

            @include text-fixed(4, $descLineHeight);
        }

        .news_lock{
            flex: 1;

            i{
                display: inline-block;
                margin: 10px 10px 0 0;
                color: #999;
                font-size: 0;
                vertical-align: middle;

                &::before{
                    font-size: 80px;
                }
            }
        }

        .news_more{
            height: grid-content-height( 1 ) + 20px;
            line-height: grid-content-height( 1 ) + 20px;
            text-align: right;
        }

        .news_tags{
            @include text-fixed(1, grid-content-height( 1 ) + 20px);
        }
    }

    &.image{
        .news_content{
            position: relative;
            width: 100%;
            height: 100%;
            margin-top: 0;
        }

        .news_detail{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: grid-content-height( 2 );
            border-radius: 0 0 5px 5px;
            background: rgba(0, 0, 0, .5);
            color: #fff;

            @include flex-container();
            @include flex-justify( right );
        }

        .news_desc{
            flex: 1;
            margin: 10px 0 0;
            padding: 0 10px;
            font-size: 14px;
            line-height: 20px;

            @include text-fixed(2, 20px);
        }

        &-hv{
            @include grid(8, 6);
        }
        &-h{
            @include grid(8, 4);
        }
        &-v{
            @include grid(4, 7);
        }
        &-vh{
            @include grid(4, 6);
        }
        &-r{
            @include grid(8, 8);
        }
    }

    &.album{
        .news_preview:nth-of-type(n+2){
            display: none;
        }
        .news_more{
            display: none;
            width: grid-content-height( 2 ) - 10px;
            height: grid-content-height( 2 ) - 10px;
            border: 1px solid #c0c0c0;
            border-radius: 5px;
            margin: 5px 5px 0 0;
            font-size: 20px;
            text-align: center;
            line-height: grid-content-height( 2 ) - 10px;
            box-sizing: border-box;
        }

        &-hv,
        &-h{
            .img-v{
                img{
                    max-width: 100%;
                    max-height: none;
                }
            }
        }
        &-v,
        &-vh{
            .img-h{
                img{
                    max-width: none;
                    max-height: 100%;
                }
            }
        }
    }

    &.document{
        @include grid-row( 8 );

        .news_desc{
            & > div{
                overflow: hidden;
            }
        }

        .doc_subTitle{
            border-bottom: 1px solid #c0c0c0;
            margin: 10px 0 0;
            font-size: 18px;
            line-height: 1.8em;
        }

        .doc_content{
            overflow: hidden;
            margin: 10px 0 0;
            padding: 0;

            @include text-fixed(2, 1.5em);
        }
    }

    &.editor{
        @include grid(8, 8);

        .news_content{
            height: grid-content-height( 8 ) - 40px;

            @include flex-direction( column );
        }
        .news_preview{
            flex: 1;
        }
    }

    &.words{
        .news_content{
            height: grid-content-height( 4 );
            margin: 0;

            @include flex-direction( column );
        }
        .news_detail{
            flex: 1;
        }
        .news_desc{
            text-indent: grid-content-width( 1 ) + 20px;

            &::first-line{
                line-height: grid-content-height( 1 ) + 15px;
            }
        }
        .news_info{
            @include flex-container();
            @include flex-justify( right );

            &::before{
                right: grid-content-height( 1 ) + 10px;
            }
        }
        .news_datetime{
            margin-right: grid-content-height( 1 ) + 20px;
        }
    }
    
    &.share{
        @include grid(8, 5);
        
        .module_title{
            color: #666;
            font-size: 14px;
            font-weight: normal;
        }
        .news_content{
            height: grid-content-height( 4 ) + 20px;
            margin: 0;

            @include flex-direction( column );
        }
        .news_detail{
            @include flex-container();
            @include flex-direction( column );

            flex: 1;
            margin-top: 10px;
        }
        .news_desc{
            flex: 1;
            color: #333;
            font-size: 20px;
            font-weight: bold;
            text-indent: grid-content-width( 1 ) + 20px;

            .icon-link{
                margin-right: 5px;
                text-indent: 0;
            }
        }
        .news_more{
            @include flex-container();
            @include flex-justify( flex-end );

            width: 100%;
            line-height: 20px;
            
            .icon-star-full{
                display: block;
                font-size: 20px;
            }
        }
    }
    
    &.idCard{
        @include grid(8, 8);
        
        .module_title{
            overflow: visible;
            height: grid-content-height( 2 ) + 20px;
            margin-left: grid-content-height( 2 ) + 10px;
            line-height:  grid-content-height( 2 ) + 20px;
            &::after{
                left: -20px;
            }
        }
        .news_content{
            margin-top: 0;
        }
        .news_preview{
            position: absolute;
            top: -($moduleBorder + $moduleBorder + grid-content-height( 2 ) + 15px);
            left: -($moduleBorder + $moduleBorder + $modulePadding);
            width: 90px;
            height: 90px;
            border: 5px solid #c0c0c0;
            border-radius: 10px 50% 50%;
        }
        .news_info{
            &::before{
                content: none;
            }
        }
        .news_tags{
            display: block;
            height: auto;
        }
    }
}

@media screen and (min-width: 1200px){
    .index{
        .loading{
            @include grid(8, 8);
        }
    }

    .news{
        &.blog{
            @include grid(16, 6);
            @include news-left();

            .news_preview{
                flex-shrink: 0;
                width: grid-content-width( 9 ) + 10px;
                height: grid-content-height( 6 ) - 40px - 40px;
            }
            .news_detail{
                height: grid-content-height( 6 ) - 40px - 40px;
            }
            .news_preview + .news_detail{
                position: relative;
                width: grid-content-width( 7 ) + 10px;
                margin-left: 10px;
                background: transparent;
                color: #666;

                .news_desc{
                    padding-top: 0;
                    padding-left: 0;
                }
            }
            .news_desc{
                padding-left: 0;
            }
        }

        &.image{
            &-hv{
            }
            &-h{
                @include grid(16, 9);
            }
            &-v{
                @include grid(8, 15);
            }
            &-vh{
                @include grid(8, 11);
            }
        }

        &.album{
            .news_content{
                @include flex-justify( space-between );
                @include flex-wrap();

                border-radius: 5px;
                overflow: hidden;
            }
            .news_preview{
                border-radius: 0;
                outline: 1px solid #999;
            }
        }

        &.share{
            @include grid(8, 6);

            .news_content{
                height: grid-content-height( 5 ) + 20px;
            }
        }
    }

    .content-all + .main{
        .news{
            &.words{
                @include news-right();
            }
        }
    }
    .blog-only + .main{
        .news{
            &:nth-child(5n+1 of .blog + .blog),
            &:nth-child(5n+3 of .blog + .blog){
                @include grid(8, 9);
                @include news-right();

                .news_content{
                    @include flex-direction( column );
                }
                .news_detail{
                    width: 100%;
                    height: grid-content-height( 9 ) - 40px - 40px;
                }
                .news_preview{
                    height: grid-content-height( 4 ) + 20px;

                    img{
                        max-width: none;
                        max-height: 100%;
                        min-height: 100%;
                    }
                }
                .news_preview + .news_detail{
                    height: grid-content-height( 3 ) + 20px;
                    margin: 10px 0 0;

                    .news_desc{
                        text-indent: 2em;
                    }
                }
                .news_desc{
                    @include text-fixed(7, $descLineHeight);
                }
                .news_lock{
                    text-align: center;

                    i{
                        display: block;
                        margin-left: 10px;
                        margin-bottom: 40px;
                    }
                }
            }
        }
    }
    .image-huge + .main{
        .news{
            &.image{
                &-hv{
                    @include grid(16, 12);
                }
            }

            &.album{
                .news_preview:nth-of-type(n+2){
                    display: flex;
                }
                .news_more{
                    display: block;
                }

                &-hv{
                    &.album-2{
                        @include grid(24, 9);

                        @include album-preview(24, 9, 2);
                    }
                    &.album-3{
                        @include grid(12, 25);

                        @include album-preview(12, 25, 1, 3);
                    }
                    &.album-4{
                        @include grid(24, 18);

                        @include album-preview(24, 18, 2, 2);
                    }
                    &.album-5{
                        @include grid(24, 14);

                        @include album-preview(24, 9, 2);

                        .news_preview{
                            &:nth-of-type(n+3){
                                @include album-preview-calc(24, 5, 3);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(24, 26);

                        @include album-preview(24, 26, 2, 3);
                    }
                    &.album-7{
                        @include grid(24, 23);

                        @include album-preview(24, 18, 2, 2);

                        .news_preview{
                            &:nth-of-type(n+5){
                                @include album-preview-calc(24, 5, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(24, 35);

                        @include album-preview(24, 35, 2, 4);
                    }
                    &.album-9{
                        @include grid(24, 31);

                        @include album-preview(24, 26, 2, 3);

                        .news_preview{
                            &:nth-of-type(n+7){
                                @include album-preview-calc(24, 5, 3);
                            }
                        }
                    }
                }
                &-h{
                    &.album-2{
                        @include grid(24, 7);

                        @include album-preview(24, 7, 2);
                    }
                    &.album-3{
                        @include grid(12, 19);

                        @include album-preview(12, 19, 1, 3);
                    }
                    &.album-4{
                        @include grid(24, 13);

                        @include album-preview(24, 13, 2, 2);
                    }
                    &.album-5{
                        @include grid(24, 26);

                        @include album-preview(24, 13, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(24, 13, 2, 2);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(24, 20);

                        @include album-preview(24, 20, 2, 3);
                    }
                    &.album-7{
                        @include grid(24, 32);

                        @include album-preview(24, 13, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(24, 19, 2, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(24, 27);

                        @include album-preview(24, 27, 2, 4);
                    }
                    &.album-9{
                        @include grid(24, 39);

                        @include album-preview(24, 13, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(24, 26, 2, 4);
                            }
                        }
                    }
                }
                &-v{
                    &.album-2{
                        @include grid(16, 15);

                        @include album-preview(16, 15, 2);
                    }
                    &.album-3{
                        @include grid(24, 15);

                        @include album-preview(24, 15, 3);
                    }
                    &.album-4{
                        @include grid(16, 30);

                        @include album-preview(16, 30, 2, 2);
                    }
                    &.album-5{
                        @include grid(24, 37);

                        @include album-preview(24, 22, 2);

                        .news_preview{
                            &:nth-of-type(n+3){
                                @include album-preview-calc(24, 15, 3);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(24, 30);

                        @include album-preview(24, 30, 3, 2);
                    }
                    &.album-7{
                        @include grid(24, 59);

                        @include album-preview(24, 44, 2, 2);

                        .news_preview{
                            &:nth-of-type(n+5){
                                @include album-preview-calc(24, 15, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(16, 60);

                        @include album-preview(16, 60, 2, 4);
                    }
                    &.album-9{
                        @include grid(24, 45);

                        @include album-preview(24, 45, 3, 3);
                    }
                }
                &-vh{
                    &.album-2{
                        @include grid(16, 12);

                        @include album-preview(16, 12, 2);
                    }
                    &.album-3{
                        @include grid(24, 12);

                        @include album-preview(24, 12, 3);
                    }
                    &.album-4{
                        @include grid(16, 24);

                        @include album-preview(16, 24, 2, 2);
                    }
                    &.album-5{
                        @include grid(24, 29);

                        @include album-preview(24, 17, 2);

                        .news_preview{
                            &:nth-of-type(n+3){
                                @include album-preview-calc(24, 12, 3);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(24, 24);

                        @include album-preview(24, 24, 3, 2);
                    }
                    &.album-7{
                        @include grid(24, 44);

                        @include album-preview(24, 32, 2, 2);

                        .news_preview{
                            &:nth-of-type(n+5){
                                @include album-preview-calc(24, 12, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(16, 48);

                        @include album-preview(16, 48, 2, 4);
                    }
                    &.album-9{
                        @include grid(24, 36);

                        @include album-preview(24, 36, 3, 3);
                    }
                }
            }
        }
    }
    .image-large + .main{
        .news{
            &.image{
                &-hv{
                    @include grid(16, 12);
                }
            }

            &.album{
                .news_preview:nth-of-type(n+2){
                    display: flex;
                }
                .news_more{
                    display: block;
                }

                &-hv{
                    &.album-2{
                        @include grid(16, 6);

                        @include album-preview(16, 6, 2);
                    }
                    &.album-3{
                        @include grid(8, 16);

                        @include album-preview(8, 16, 1, 3);
                    }
                    &.album-4{
                        @include grid(16, 12);

                        @include album-preview(16, 12, 2, 2);
                    }
                    &.album-5{
                        @include grid(16, 23);

                        @include album-preview(16, 12, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(16, 11, 2, 2);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(24, 12);

                        @include album-preview(24, 12, 3, 2);
                    }
                    &.album-7{
                        @include grid(16, 29);

                        @include album-preview(16, 12, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(16, 17, 2, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(16, 23);

                        @include album-preview(16, 23, 2, 4);
                    }
                    &.album-9{
                        @include grid(24, 18);

                        @include album-preview(24, 18, 3, 3);
                    }
                }
                &-h{
                    &.album-2{
                        @include grid(16, 5);

                        @include album-preview(16, 5, 2);
                    }
                    &.album-3{
                        @include grid(8, 12);

                        @include album-preview(8, 12, 1, 3);
                    }
                    &.album-4{
                        @include grid(16, 9);

                        @include album-preview(16, 9, 2, 2);
                    }
                    &.album-5{
                        @include grid(16, 17);

                        @include album-preview(16, 9, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(16, 8, 2, 2);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(24, 9);

                        @include album-preview(24, 9, 3, 2);
                    }
                    &.album-7{
                        @include grid(16, 21);

                        @include album-preview(16, 9, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(16, 12, 2, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(16, 18);

                        @include album-preview(16, 18, 2, 4);
                    }
                    &.album-9{
                        @include grid(24, 13);

                        @include album-preview(24, 13, 3, 3);
                    }
                }
                &-v{
                    &.album-2{
                        @include grid(8, 8);

                        @include album-preview(8, 8, 2, 1);
                    }
                    &.album-3{
                        @include grid(8, 22);

                        @include album-preview(8, 15, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 7, 2);
                            }
                        }
                    }
                    &.album-4{
                        @include grid(8, 15);

                        @include album-preview(8, 15, 2, 2);
                    }
                    &.album-5{
                        @include grid(8, 29);

                        @include album-preview(8, 15, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 14, 2, 2);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(8, 21);

                        @include album-preview(8, 21, 2, 3);
                    }
                    &.album-7{
                        @include grid(8, 36);

                        @include album-preview(8, 15, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 21, 2, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(8, 28);

                        @include album-preview(8, 28, 2, 4);
                    }
                    &.album-9{
                        @include grid(8, 43);

                        @include album-preview(8, 15, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 28, 2, 4);
                            }
                        }
                    }
                }
                &-vh{
                    &.album-2{
                        @include grid(8, 6);

                        @include album-preview(8, 6, 2);
                    }
                    &.album-3{
                        @include grid(8, 17);

                        @include album-preview(8, 11, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 6, 2);
                            }
                        }
                    }
                    &.album-4{
                        @include grid(8, 12);

                        @include album-preview(8, 12, 2, 2);
                    }
                    &.album-5{
                        @include grid(8, 23);

                        @include album-preview(8, 11, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 12, 2, 2);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(8, 18);

                        @include album-preview(8, 18, 2, 3);
                    }
                    &.album-7{
                        @include grid(8, 29);

                        @include album-preview(8, 11, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 18, 2, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(8, 24);

                        @include album-preview(8, 24, 2, 4);
                    }
                    &.album-9{
                        @include grid(8, 35);

                        @include album-preview(8, 11, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 24, 2, 4);
                            }
                        }
                    }
                }
            }
        }
    }
    .image-normal + .main{
        .news{
            &.album{
                .news_preview:nth-of-type(n+2){
                    display: flex;
                }
                .news_more{
                    display: block;
                }

                &-hv{
                    &.album-2{
                        @include grid(16, 6);

                        @include album-preview(16, 6, 2);
                    }
                    &.album-3{
                        @include grid(8, 16);

                        @include album-preview(8, 16, 1, 3);
                    }
                    &.album-4{
                        @include grid(16, 12);

                        @include album-preview(16, 12, 2, 2);
                    }
                    &.album-5{
                        @include grid(8, 11);

                        @include album-preview(8, 6, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 5, 2, 2);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(8, 16);

                        @include album-preview(8, 11, 1, 2);

                        .news_preview{
                            &:nth-of-type(n+3){
                                @include album-preview-calc(8, 5, 2, 2);
                            }
                        }
                    }
                    &.album-7{
                        @include grid(8, 14);

                        @include album-preview(8, 6, 1, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 8, 2, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(8, 19);

                        @include album-preview(8, 11, 1, 2);

                        .news_preview{
                            &:nth-of-type(n+3){
                                @include album-preview-calc(8, 8, 2, 3);
                            }
                        }
                    }
                    &.album-9{
                        @include grid(8, 16);

                        @include album-preview(8, 6, 1, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 10, 2, 4);
                            }
                        }
                    }
                }
                &-h{
                    &.album-2{
                        @include grid(16, 5);

                        @include album-preview(16, 5, 2);
                    }
                    &.album-3{
                        @include grid(8, 12);

                        @include album-preview(8, 12, 1, 3);
                    }
                    &.album-4{
                        @include grid(16, 9);

                        @include album-preview(16, 9, 2, 2);
                    }
                    &.album-5{
                        @include grid(8, 8);

                        @include album-preview(8, 4, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 4, 2, 2);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(8, 12);

                        @include album-preview(8, 8, 1, 2);

                        .news_preview{
                            &:nth-of-type(n+3){
                                @include album-preview-calc(8, 4, 2, 2);
                            }
                        }
                    }
                    &.album-7{
                        @include grid(8, 10);

                        @include album-preview(8, 4, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 6, 2, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(8, 14);

                        @include album-preview(8, 8, 1, 2);

                        .news_preview{
                            &:nth-of-type(n+3){
                                @include album-preview-calc(8, 6, 2, 3);
                            }
                        }
                    }
                    &.album-9{
                        @include grid(8, 12);

                        @include album-preview(8, 4, 1);

                        .news_preview{
                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 8, 2, 4);
                            }
                        }
                    }
                }
                &-v{
                    &.album-2{
                        @include grid(8, 8);

                        @include album-preview(8, 8, 2, 1);
                    }
                    &.album-3{
                        @include grid(8, 12);

                        @include album-preview(8, 8, 1);

                        .news_preview{
                            &.img-v{
                                img{
                                    max-width: 100%;
                                    max-height: none;
                                }
                            }

                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 4, 2);
                            }
                        }
                    }
                    &.album-4{
                        @include grid(8, 15);

                        @include album-preview(8, 15, 2, 2);
                    }
                    &.album-5{
                        @include grid(8, 16);

                        @include album-preview(8, 8, 1);

                        .news_preview{
                            &.img-v{
                                img{
                                    max-width: 100%;
                                    max-height: none;
                                }
                            }

                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 8, 2, 2);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(8, 24);

                        @include album-preview(8, 16, 1, 2);

                        .news_preview{
                            &.img-v{
                                img{
                                    max-width: 100%;
                                    max-height: none;
                                }
                            }

                            &:nth-of-type(n+3){
                                @include album-preview-calc(8, 8, 2, 2);
                            }
                        }
                    }
                    &.album-7{
                        @include grid(8, 20);

                        @include album-preview(8, 8, 1);

                        .news_preview{
                            &.img-v{
                                img{
                                    max-width: 100%;
                                    max-height: none;
                                }
                            }

                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 12, 2, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(8, 28);

                        @include album-preview(8, 16, 1, 2);

                        .news_preview{
                            &.img-v{
                                img{
                                    max-width: 100%;
                                    max-height: none;
                                }
                            }

                            &:nth-of-type(n+3){
                                @include album-preview-calc(8, 12, 2, 3);
                            }
                        }
                    }
                    &.album-9{
                        @include grid(8, 24);

                        @include album-preview(8, 8, 1);

                        .news_preview{
                            &.img-v{
                                img{
                                    max-width: 100%;
                                    max-height: none;
                                }
                            }

                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 16, 2, 4);
                            }
                        }
                    }
                }
                &-vh{
                    &.album-2{
                        @include grid(8, 6);

                        @include album-preview(8, 6, 2);
                    }
                    &.album-3{
                        @include grid(8, 12);

                        @include album-preview(8, 8, 1);

                        .news_preview{
                            &.img-v{
                                img{
                                    max-width: 100%;
                                    max-height: none;
                                }
                            }

                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 4, 2);
                            }
                        }
                    }
                    &.album-4{
                        @include grid(8, 12);

                        @include album-preview(8, 12, 2, 2);
                    }
                    &.album-5{
                        @include grid(8, 16);

                        @include album-preview(8, 8, 1);

                        .news_preview{
                            &.img-v{
                                img{
                                    max-width: 100%;
                                    max-height: none;
                                }
                            }

                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 8, 2, 2);
                            }
                        }
                    }
                    &.album-6{
                        @include grid(8, 24);

                        @include album-preview(8, 16, 1, 2);

                        .news_preview{
                            img{
                                max-width: 100%;
                                max-height: none;
                            }

                            &:nth-of-type(n+3){
                                @include album-preview-calc(8, 8, 2, 2);
                            }
                        }
                    }
                    &.album-7{
                        @include grid(8, 20);

                        @include album-preview(8, 8, 1);

                        .news_preview{
                            &.img-v{
                                img{
                                    max-width: 100%;
                                    max-height: none;
                                }
                            }

                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 12, 2, 3);
                            }
                        }
                    }
                    &.album-8{
                        @include grid(8, 28);

                        @include album-preview(8, 16, 1, 2);

                        .news_preview{
                            &.img-v{
                                img{
                                    max-width: 100%;
                                    max-height: none;
                                }
                            }

                            &:nth-of-type(n+3){
                                @include album-preview-calc(8, 12, 2, 3);
                            }
                        }
                    }
                    &.album-9{
                        @include grid(8, 24);

                        @include album-preview(8, 8, 1);

                        .news_preview{
                            &.img-v{
                                img{
                                    max-width: 100%;
                                    max-height: none;
                                }
                            }

                            &:nth-of-type(n+2){
                                @include album-preview-calc(8, 16, 2, 4);
                            }
                        }
                    }
                }
            }
        }
    }
    .image-small + .main{
        .news{
            &.image{
                &-hv{
                    @include grid(8, 6);
                }
                &-h{
                    @include grid(8, 4);
                }
                &-v{
                    @include grid(4, 7);
                }
                &-vh{
                    @include grid(4, 6);
                }
                &-r{
                    @include grid(8, 8);
                }
            }
        }
    }
    .image-tiny + .main{
        .news{
            &.image{
                @include grid(4, 4);

                .img-h{
                    img{
                        max-width: none;
                        max-height: 100%;
                    }
                }
                .img-v{
                    img{
                        max-width: 100%;
                        max-height: none;
                    }
                }
            }
        }
    }
}

@import "./blog.scss";

@import "./album.scss";

@import "./image.scss";

@import "./document.scss";

@import "./editor.scss";

@import "./valhalla.scss";

@import "./resume.scss";

@import "./words.scss";

@import "./share.scss";

@import "./idCard.scss";