@import "../element/icon.scss";

.blog{
    &::before{
        @extend %icon-blog;
    }

    &_short{
        min-height: 1.5em;
        border-radius: 5px;
        margin-bottom: 10px;
        padding: 5px;
        background: #fff;

        .img.container{
            border-top: 1px solid #c0c0c0;
            margin-top: 10px;
            padding-top: 10px;
            font-size: 0;

            img{
                max-width: 600px;
                border-radius: 5px;
            }
        }
    }

    &_content{
        min-height: 500px;
        border-radius: 5px;
        padding: 5px;
        background: #fff;
        font-size: 16px;
        line-height: 24px;

        .container.flex{
            margin: 20px -10px;

            &-v{
                margin: 20px 0;

                label, input{
                    margin-bottom: 10px;
                }
            }

            .btn{
                margin: 0 10px;
            }
        }

        img{
            border-radius: 5px;
        }
    }

    blockquote{
        display: block;
        border: 1px dashed #c0c0c0;
        border-radius: 10px;
        padding: 10px;
        background: #fafafa;
        color: #999;
        line-height: 2em;
    }

    .img{
        max-width: 100%;
        vertical-align: top;
    }
    .bold{
        font-weight: bold;
    }

    .block{
        display: block;
        width: 100%;
    }
}