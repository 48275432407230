@mixin flex-justify($justify: space-between){
    @if $justify == left{
        justify-content: flex-start;
    }
    @else if $justify == right{
        justify-content: flex-end;
    }
    @else{
        justify-content: $justify;
    }
}
@mixin flex-align($align: top){
    @if $align == top{
        align-items: flex-start;
    }
    @else if $align == bottom{
        align-items: flex-end;
    }
    @else{
        align-items: $align;
    }
}

@mixin flex-direction($direction: row){
    flex-direction: $direction;
}

@mixin flex-wrap($wrap: wrap){
    flex-wrap: $wrap;
}

@mixin flex-container(){
    display: flex;

    @include flex-justify();
    @include flex-align();
}
@mixin flex-container-v(){
    @include flex-direction( column );
    @include flex-justify( flex-start );
    @include flex-align( center );
}

.container{
    &.flex{
        @include flex-container();

        &.wrap{
            @include flex-wrap();
        }

        /* 水平方向 */
        &.left{
            @include flex-justify( left );
        }
        &.center{
            @include flex-justify( center );
        }
        &.right{
            @include flex-justify( right );
        }
        &.round{
            @include flex-justify( space-around );
        }

        /* 垂直方向 */
        &.top{
            @include flex-align( top );
        }
        &.justify{
            @include flex-align( center );
        }
        &.bottom{
            @include flex-align( bottom );
        }
        &.stretch{
            @include flex-align( stretch );
        }

        /**
         * 纵向排列 flex-direction: column
         * justify-content 负责管上下
         * align-items 负责管左右
         */
        &-v{
            @include flex-container-v();

            &.top{
                @include flex-justify( flex-start );
            }
            &.center{
                @include flex-justify( center );
            }
            &.bottom{
                @include flex-justify( flex-end );
            }

            &.left{
                @include flex-align( flex-start );
            }
            &.justify{
                @include flex-align( center );
            }
            &.right{
                @include flex-align( flex-end );
            }
            &.stretch{
                @include flex-align( stretch );
            }
        }
    }
}