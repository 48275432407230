@import "../element/icon.scss";

.document{
    &::before{
        @extend %icon-document;
    }

    color: #333;

    .icon{
        color: #666;
    }
    
    .doc_section{
        border-radius: 10px;
        padding: 10px;
        background: #f1f1f1;
    }
    .section_title{
        height: 50px;
        padding: 0 10px;
        cursor: pointer;
        font-size: 20px;
        line-height: 50px;

        h3{
            margin: 0;
        }

        .icon{
            font-size: 30px;
        }
    }

    .content_list{
        border-top: 1px solid #c0c0c0;
        margin: -5px 0 -10px;
    }

    .doc_content{
        border-radius: 5px;
        margin: 10px 0;
        padding: 10px;
        background: #fff;

        .content_title{
            height: 40px;
            padding: 0 10px;
            line-height: 40px;

            h4{
                margin: 0;
            }

            .icon{
                font-size: 20px;
            }
        }
        .detail{
            border-top: 1px solid #c0c0c0;
            padding: 10px;
            font-size: 14px;
            line-height: 2em;
        }
    }

    sub{
        vertical-align: super;
    }
}