@import "../element/icon.scss";

.image{
    &::before{
        @extend %icon-image;
    }

    &-v{
        .img_main{
            max-width: 600px;
        }
    }
    &-h{

    }

    .module_title{
        &::after{
            content: none;
        }
    }

    img{
        border-radius: 3px;
    }

    .img_preview{
        position: relative;
        border-radius: 5px;
        padding: 10px;
        background: #fff;
    }
    .img_main{
        border-radius: 5px;
        margin: auto;
        font-size: 0;

        .icon{
            width: 50px;
            height: 50px;
            border-radius: 5px;
            background: rgba(0, 0, 0, .2);
            color: rgba(255, 255, 255, .6);
            font-size: 0;
            text-align: center;
            &::before{
                font-size: 40px;
                line-height: 50px;
            }
        }
    }
    .prev,
    .next{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 40%;
    }
    .prev{
        left: 0;
    }
    .next{
        right: 0;
    }
}

@media screen and (min-width: 1200px){
    .image{
        .prev,
        .next{
            opacity: 0;

            &:hover{
                opacity: 1;
            }
        }
    }
}