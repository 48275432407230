@mixin grid-column($column){
    grid-column: span $column;
}

@mixin grid-row($row){
    grid-row: span $row;
}

@mixin grid($column, $row){
    @include grid-column( $column );
    @include grid-row( $row );
}

@mixin grid-offset($start, $end){
    grid-column-start: $start;
    grid-column-end: $end;
}

$gridColWidth: 50px;
$gridColPercent: 12.5%;
$gridRowHeight: 50px;

.container{
    &.grid{
        display: grid;
        grid-template-columns: repeat(auto-fill, $gridColWidth);
        grid-auto-rows: $gridRowHeight;
        grid-auto-flow: row dense;

        &.percent{
            grid-template-columns: repeat(auto-fill, $gridColPercent);

            .full{
                @include grid-column( 8 );
            }
            .half{
                @include grid-column( 4 );
            }
            .quarter{
                @include grid-column( 2 );
            }
        }

        .full{
            @include grid-column( 20 );
        }
        .half{
            @include grid-column( 10 );
        }
        .quarter{
            @include grid-column( 5 );
        }
    }
}

@media screen and (max-width: 450px){
    .container{
        &.grid{
            grid-template-columns: repeat(auto-fill, $gridColPercent);

            .full{
                @include grid-column( 8 );
            }
            .half{
                @include grid-column( 4 );
            }
            .quarter{
                @include grid-column( 2 );
            }
        }
    }
}