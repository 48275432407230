@import "../config/color.config.scss";
@import "./icon.scss";

.tag{
    position: relative;
    display: inline-block;
    min-width: 4em;
    height: 30px;
  
    border: 1px solid $tag_bd_c;
    border-left: 0;
    border-radius: 2px 5px 5px 2px;
 
    margin: 5px 0 0 18px;
    padding: 0 .8em;

    box-sizing: border-box;

    background: $tag_bg_c;
    color: #666;

    font-size: 12px;
    font-weight: 600;
    line-height: 28px;

    text-align: center;

    cursor: pointer;

    &::before{
        position: absolute;
        left: -10px;
        width: 22px;
        height: 22px;
        border: 1px solid transparent;
        border-left-color: $tag_bd_c;
        border-bottom-color: $tag_bd_c;
        border-radius: 5px;
        transform: translateY(2px) rotate(45deg);
        content: "";
    }
    &::after{
        position: absolute;
        left: -5px;
        top: 11px;
        border: 3px solid #666;
        border-radius: 3px;
        content: "";
    }

    &-checked{
        border-color: $tag_checked_bd_c;
        background: $tag_checked_bg_c;
        color: $tag_checked_c;
        
        &::before{
            position: absolute;
            width: 0;
            height: 0;
            border-width: 11px;
            border-left-color: $tag_checked_bd_c;
            border-bottom-color: $tag_checked_bd_c;
            transform: translateY(3px) rotate(45deg);
        }
        &::after{
            border-color: $tag_checked_c;
        }
    }
}

.tagList{
    margin: -5px;
}