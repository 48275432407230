@import "../element/scrollbar.scss";
@import "../element/icon.scss";

.valhalla{
    &::before{
        @extend %icon-valhalla;
    }

    .valhalla_preview{
        position: relative;
        border-radius: 10px 10px 0 0;
        padding: 10px;
        background: #fff;
    }

    .main-preview{
        max-width: 500px;
        min-height: 400px;
        margin: auto;
    }
    .prev,
    .next{
        position: absolute;
        top: 0;
        bottom: 0;
        width: 40%;
    }
    .prev{
        left: 0;
    }
    .next{
        right: 0;
    }
    .icon{
        width: 50px;
        height: 50px;
        border-radius: 5px;
        background: rgba(0, 0, 0, .3);
        color: rgba(255, 255, 255, .7);
        font-size: 0;
        text-align: center;
        
        &::before{
            font-size: 40px;
            line-height: 50px;
        }
    }

    .preview-list{
        border-bottom: 1px solid #c0c0c0;
        padding: 10px 10px 0;
        background: #fff;

        @include scroll-bar();

        .current{
            outline: 1px dashed #c0c0c0;
            outline-offset: 2px;
        }
        
        .container.img{
            flex-shrink: 0;
            margin: 5px;
            width: 25%;
            max-width: 100px;
        }
    }
    .description{
        border-radius: 0 0 10px 10px;
        background: #fff;

        .container.flex{
            max-width: 500px;
            margin: 10px auto;
            font-size: 16px;
            line-height: 20px;

            div:nth-child(1){
                flex-shrink: 0;
            }
        }
    }
}