.footer{
    overflow: hidden;
    position: sticky;
    top: 10000px;
    left: 0;
    right: 0;
    z-index: 100;
    //height: 80px;
    height: 40px;
    background: #000;
    color: #fff;
    font-size: 16px;
    line-height: 40px;

    a{
        color: #fff;
    }
    
    .icon{
        margin: 0 10px;
        font-size: 24px;
    }
    .menu{
        position: sticky;
        top: 0;
    }
    .copy{
        font-size: 12px;
        text-align: center;
    }

    img{
        margin: 0 5px 0 10px;
        vertical-align: text-bottom;
    }
}