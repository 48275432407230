@import "src/sass/config/color.config";

//----- Code 代码 -----
/*code*/.code{
    display: inline-block;
    border: 1px solid $code_bd_c;
    border-radius: 4px;
    padding: 0 4px;

    background-color: $code_bg_c;
    //color: @code_c;
    color: #666;
    font-size: 90%;

    font-family: consolas,monospace;
    font-weight: 600;

    text-indent: 0;
    word-break: break-word;
    word-wrap: break-word;

    &:hover{
        border-color: $code-hover_bd_c;
        background: $code-hover_bg_c;
        color: $code-hover_c;

        cursor: pointer;
    }
}