@mixin scroll-bar($thumb: #c0c0c0, $track-piece: transparent){
    &::-webkit-scrollbar{
        height: 5px;
        width: 5px;
    }
    &::-webkit-scrollbar-thumb{
        height: 50px;
        border-radius: 20px;
        background: $thumb;
    }
    &::-webkit-scrollbar-track-piece{
        background: $track-piece;
    }
}

.scrollbar{
    @include scroll-bar();
}