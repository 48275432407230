@keyframes fadeIn{
    0%{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes fadeOut{
    0%{
        opacity: 1;
    }
    to{
        opacity: 0;
    }
}

.animate-fadeIn{
    animation-name: fadeIn;
}

.animate-fadeOut{
    animation-name: fadeOut;
}